export function useNavigation() {
  const uiStore = useUiStore();
  const { closeMobileNav, } = uiStore;

  const sportsBettingStore = useSportsBettingStore();
  const { previousSubPath, } = storeToRefs(sportsBettingStore);

  const router = useRouter();
  const route = useRoute();

  function handleBasicRedirectTo(path, query = undefined) {
    closeMobileNav();
    router.push({ path, query, });
  }

  function sportsBettingRedirect(path) {
    closeMobileNav();

    if (route.fullPath.includes('sports') && path.includes('sports') && previousSubPath.value && previousSubPath.value !== '/') {
      return router.replace({ path, });
    } else {
      return router.push({ path, });
    }
  }

  function handleOpenNewTab(url) {
    if (!url) {
      return;
    }

    window.open(url, '_blank');
  }

  return {
    handleBasicRedirectTo,
    sportsBettingRedirect,
    handleOpenNewTab,
  };
}
